const KlubrLogo = ({
                       border = '#000000',
                       bg = '#FFFFFF',
                       dotBorder = '#000000',
                       dotBg = '#FFFFFF',
                       context = 'header',
                       title = 'Logo Klubr',
                       desc,
                       width = 185,
                   }: {
    border?: string;
    bg?: string;
    dotBorder?: string;
    dotBg?: string;
    context?: string;
    title?: string;
    desc?: string;
    width?: number;
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width={6455}
            height={1815}
            viewBox="0 0 6455 1815"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
                width: `auto`,
                maxWidth: `${width}px`,
                height: 'auto',
            }}
            aria-labelledby={(!!title ? `logoTitle-${context}` : '') + (!!desc ? ` logoDesc-${context}` : '')}
            role="img"
        >
            {!!title && <title id={`logoTitle-${context}`}>{title}</title>}
            {!!desc && <desc id={`logoDesc-${context}`}>{desc}</desc>}
            <path
                d="m811.301 1569.43 113.414 150.06a181.315 181.315 0 0 0 144.645 71.99h526.68c68.68 0 131.47-38.81 162.18-100.24 30.7-61.43 24.07-134.95-17.15-189.88 0 0-317.34-422.95-477.23-636.038 155.7-171.728 437.42-482.456 437.42-482.456 48.17-53.134 60.5-129.672 31.44-195.242-29.06-65.571-94.04-107.85-165.76-107.85h-494.76a181.311 181.311 0 0 0-134.267 59.464L811.301 278.753v-18.912c0-100.135-81.175-181.31-181.31-181.31H184.699C84.648 78.531 3.507 159.577 3.39 259.628L1.802 1610.9a181.306 181.306 0 0 0 53.029 128.34 181.301 181.301 0 0 0 128.28 53.18h446.88c100.135 0 181.31-81.17 181.31-181.31v-41.68Z"
                style={{
                    fill: border,
                }}
            />
            <path
                d="M104.588 198.496H549.88v488.492l442.185-487.249h494.755L948.879 793.075l567.051 755.755H989.248l-337.037-445.94-102.331 113.59v333.29H103l1.588-1351.274Z"
                style={{
                    fill: bg,
                }}
            />
            <path
                d="M1643.8.011c-100.14 0-181.31 81.175-181.31 181.31V1610.58c0 100.14 81.17 181.31 181.31 181.31h434.86c100.13 0 181.31-81.17 181.31-181.31V181.321c0-100.135-81.18-181.31-181.31-181.31H1643.8Z"
                style={{
                    fill: border,
                }}
            />
            <path
                d="M1563.68 119.975h434.863v1429.26H1563.68z"
                style={{
                    fill: bg,
                }}
            />
            <path
                d="m2258.68 556.851-182.77-.124s-.32 574.283.07 588.243c.44 15.98-2.48 20.4 4.62 93.61 7.83 80.73 14.07 108.63 39.87 186.04 30.39 91.17 100.49 195.63 183.79 259.79 78.74 60.66 196.93 126.41 368.25 128.54 101.95 1.27 184.86-16.25 247.57-38.43 24.29 12.08 51.67 18.88 80.64 18.88h411.58c100.14 0 181.31-81.18 181.31-181.31V556.85c0-100.135-81.17-181.31-181.31-181.31h-435.61c-57.71 0-109.12 26.96-142.33 68.974-33.2-42.014-84.62-68.974-142.32-68.974h-433.36v181.311Zm179.72 181.308c-.1 166.274-.18 388.201.06 396.751.32 11.71-2.14 14.97 3.07 68.67 4.47 46.17 8.19 62.1 22.95 106.37 10.16 30.49 33.2 65.73 61.06 87.19 32.41 24.97 80.95 52.34 151.47 53.22 32.88.41 61.4-2.41 85.11-7.08-127.46-18.96-195.56-111.75-228.64-212.51-21.79-66.36-23.06-134.76-22.75-158.4V738.159h-72.33Zm724.32 692.621h68.27V738.159H3158v343.681l-.22-8.93s3.88 158.69-93.8 269.37c2.32.86 4.64 1.78 6.94 2.75 40.69 17.08 72.78 47.91 91.8 85.75Z"
                style={{
                    fill: border,
                }}
            />
            <path
                d="M2188.57 495.504h433.36v515.976s-10.52 206.54 141.19 191.52c151.72-15.02 143.46-182.51 143.46-182.51V495.504h435.61V1550.74h-411.58v-99.89s-36.8 36.09-78.86 62.38-126.18 58.58-247.1 57.08c-120.92-1.5-204.29-48.07-259.87-90.88-55.57-42.81-102.14-112.66-122.42-173.49-20.28-60.84-25.26-82.76-31.41-146.21-6.15-63.45-3.46-67.29-3.84-81.13-.39-13.85 0-583.096 0-583.096"
                style={{
                    fill: bg,
                }}
            />
            <path
                d="M4101.28 1779.55c6.59 2.01 13.41 3.97 20.49 5.86 89.33 23.91 227.76 49.9 383.77 4.61 156.64-45.48 291.87-138.86 390.4-286.66 94.13-141.19 119.18-337.58 111.94-495.66-7.84-171.179-144.44-412.018-231.99-481.272-127.79-101.087-230.54-160.001-458.7-168.541-29.6-1.109-57.48-.249-83.65 2.168V181.378c0-100.135-81.18-181.31-181.31-181.31h-436.4c-48.09 0-94.22 19.111-128.22 53.127a181.32 181.32 0 0 0-53.09 128.246l.5 1431.019c.04 100.11 81.2 181.25 181.31 181.25h414.71c24.36 0 48.21-4.9 70.24-14.16Zm127.22-725.95-.01.14c.64-.8.98-1.33.98-1.33l-.97 1.19Z"
                style={{
                    fill: border,
                }}
            />
            <path
                d="m3536.22 1551.05-.5-1431.017h436.4v445.941s82.62-94.821 258.18-88.25c175.55 6.572 254.66 51.775 352.99 129.558 62.9 49.757 157.72 224.378 163.36 347.364 5.63 122.984-8.45 276.954-81.68 386.794-73.23 109.84-173.68 179.32-290.1 213.11-116.41 33.8-219.68 12.21-286.34-5.63-66.65-17.84-97.7-45.57-137.6-82.08-.59-.53 0 84.21 0 84.21h-414.71Zm600.03-733.027c69.22 0 159.56 40.199 168.27 186.737 1.27 21.22 4.93 86.44-24.3 139.97-13.12 24.03-46.14 79.68-139.36 85.21-15.87.95-79.06 9.54-139.66-67.98-12.05-15.41-36.92-54.45-36.3-133.21.18-23.08-4-93.211 39.68-147.047 12.56-15.481 49.04-63.68 131.67-63.68Z"
                style={{
                    fill: bg,
                }}
            />
            <path
                d="M5464.75 398.765c-25.48-13.67-54.62-21.426-85.57-21.426h-413.71c-100.13 0-181.31 81.176-181.31 181.31V1612.23c0 100.07 81.07 181.21 181.13 181.31l436.29.44a181.32 181.32 0 0 0 128.32-53.04 181.277 181.277 0 0 0 53.17-128.27v-483.55c.48-5.74 1.05-12.68 1.07-12.93.3-4.08 1.35-9.55 2.18-13.41 7.68-1.77 24.43-5.41 33.46-5.74 31.16-1.12 56.65 4.48 56.65 4.48 54 13.14 111.03.79 154.76-33.51 43.73-34.29 69.31-86.746 69.42-142.318 0 0 .59-313.657.17-376.397-1.12-168.022-176.88-190.856-178.65-190.884-103.96-1.625-189.04 15.417-257.38 40.354Z"
                style={{
                    fill: border,
                }}
            />
            <path
                d="M4885.36 1550.89V497.304h413.71v112.717s96.44-135.477 340.12-131.667c.73.011 0 385.649 0 385.649s-297.95-72.499-316 177.807c-.03.38-1.54 18.45-1.54 18.45v491.06l-436.29-.43Z"
                style={{
                    fill: bg,
                }}
            />
            <g className="logo-dot">
                <path
                    d="m6009.57 953.359 6.68-.145c-79.84-.284-220.66 25.738-321.26 136.446-101.63 111.85-108.41 236.47-109.49 272.49-1.05 35.06-7.49 181.77 106.57 308.92 113.47 126.48 249.21 141.96 335.09 141.44 84.09-.51 159.23-5.33 282.11-103.81 150.48-120.61 147.26-340.05 146.98-344.85-7.55-128.53-54.56-215.08-110.82-275.58-124.06-133.412-312.08-135.232-335.86-134.911Z"
                    style={{
                        fill: dotBorder,
                    }}
                />
                <path
                    d="M5935.49 1073.18s244.88-11.55 259.65 239.95c.15 2.67 4.3 125.68-79.37 192.74-74.18 59.46-119.06 63.68-169.82 63.99-50.76.31-131.97-6.46-199.04-81.21-67.06-74.76-60.91-161.82-60.29-182.43.61-20.61 4.31-91.98 62.45-155.97 58.14-63.99 140.28-77.24 186.42-77.07Z"
                    className="logo-dot-bg"
                    style={{
                        fill: dotBg,
                    }}
                />
            </g>
        </svg>
    );
};

export default KlubrLogo;
