'use client';

import React, { useEffect } from 'react';
import ImageHtml from '@/components/media/ImageHtml';
import { useAppSelector } from '@/core/store/hooks';
import { selectSession } from '@/core/store/modules/authSlice';
import Link from 'next/link';
import Image from 'next/image';
import switchSvg from '../../../../../public/images/icons/switch.svg';
import { signOut } from 'next-auth/react';
import { usePathname } from 'next/navigation';

const ClientController: React.FC<{ component: string; txtColor: string }> = (props) => {
	const selectedSession = useAppSelector(selectSession);
	const pathName = usePathname();

	useEffect(() => {
		document.querySelectorAll('#LOGOUT_BTN')?.forEach((_) => {
			_?.addEventListener('click', () => {
				signOut();
			});
		});
		document.querySelector('#MOBILE_DRAWER_OPENER')?.addEventListener('click', () => {
			const MOBILE_DRAWER = document.querySelector('#MOBILE_DRAWER') as HTMLDivElement;
			if (MOBILE_DRAWER) {
				MOBILE_DRAWER.style.right = '0';
			}
		});
		document.querySelector('#MOBILE_DRAWER_CLOSER')?.addEventListener('click', () => {
			const MOBILE_DRAWER = document.querySelector('#MOBILE_DRAWER') as HTMLDivElement;
			if (MOBILE_DRAWER) {
				MOBILE_DRAWER.style.right = '-100%';
			}
		});

		return () => {
			document.querySelectorAll('#LOGOUT_BTN')?.forEach((_) => {
				_?.removeEventListener('click', () => {
					signOut();
				});
			});
			document.querySelector('#MOBILE_DRAWER_OPENER')?.removeEventListener('click', () => {
				const MOBILE_DRAWER = document.querySelector('#MOBILE_DRAWER') as HTMLDivElement;
				if (MOBILE_DRAWER) {
					MOBILE_DRAWER.style.right = '0';
				}
			});
			document.querySelector('#MOBILE_DRAWER_CLOSER')?.removeEventListener('click', () => {
				const MOBILE_DRAWER = document.querySelector('#MOBILE_DRAWER') as HTMLDivElement;
				if (MOBILE_DRAWER) {
					MOBILE_DRAWER.style.right = '-100%';
				}
			});
		};
	}, []);

	useEffect(() => {
		const MOBILE_DRAWER = document.querySelector('#MOBILE_DRAWER') as HTMLDivElement;
		if (MOBILE_DRAWER) {
			MOBILE_DRAWER.style.right = '-100%';
		}
	}, [pathName]);

	const getComponent = () => {
		switch (props.component) {
			case 'USER_DETAIL_NAV':
				if (selectedSession.status === 'authenticated') {
					return (
						<>
							<ImageHtml
								src={selectedSession?.data?.avatar?.url || selectedSession?.data?.image || ''}
								loading='lazy'
								decoding='async'
								alt='logo'
								className='img h-[45px] w-[45px] object-cover rounded-full'
								width={45}
								height={45}
								namedtransformation={'avatar'}
								nosizes={true}
							/>
							<div className='items-start cursor-pointer'>
								<div className='flex flex-col justify-center '>
									<strong
										className='font-primary stickyHeaderTxtColor'
										style={{ color: props.txtColor }}
									>
										{selectedSession?.data?.nom || selectedSession?.data?.email}
									</strong>
								</div>
							</div>
						</>
					);
				}
				return (
					<>
						<div className='min-w-[200px]'>
							<div className='animate-pulse flex items-center justify-start gap-2'>
								<div className='rounded-full bg-gray-300 h-[45px] w-[45px]'></div>
								<div className='flex-1 py-1 mx-0'>
									<div className='space-y-3'>
										<div className='grid grid-cols-3 gap-4'>
											<div className='h-2 bg-gray-300 rounded col-span-2'></div>
											<div className='h-2 bg-gray-300 rounded col-span-1'></div>
										</div>
										<div className='h-2 bg-gray-300 rounded'></div>
									</div>
								</div>
							</div>
						</div>
					</>
				);
			case 'USER_DETAIL_DROPDOWN':
				if (selectedSession.status === 'authenticated') {
					return (
						<>
							<ImageHtml
								src={selectedSession?.data?.avatar?.url || selectedSession?.data?.image || ''}
								loading='lazy'
								decoding='async'
								alt='logo'
								className='img h-[45px] w-[45px] object-cover rounded-full'
								width={45}
								height={45}
								namedtransformation={'avatar'}
								nosizes={true}
							/>
							<div className='flex flex-col items-start gap-0'>
								<strong className='font-primary'>{selectedSession?.data?.nom}</strong>
								<p>{selectedSession?.data?.email}</p>
							</div>
						</>
					);
				}
				return (
					<div className='min-w-[200px]'>
						<div className='animate-pulse flex items-center justify-start gap-2'>
							<div className='rounded-full bg-gray-300 h-[45px] w-[45px]'></div>
							<div className='flex-1 py-1 mx-0'>
								<div className='space-y-3'>
									<div className='grid grid-cols-3 gap-4'>
										<div className='h-2 bg-gray-300 rounded col-span-2'></div>
										<div className='h-2 bg-gray-300 rounded col-span-1'></div>
									</div>
									<div className='h-2 bg-gray-300 rounded'></div>
								</div>
							</div>
						</div>
					</div>
				);
			case 'USER_ADMIN_BTN':
				if (selectedSession.status === 'authenticated') {
					return selectedSession?.data && selectedSession?.data?.klubr_membres?.length > 0 ? (
						<>
							<Link
								href={'/admin'}
								className={
									'm-2 py-4 rounded-xl font-medium flex items-center justify-center gap-2 bg-[#E4E4E5] text-black'
								}
							>
								<Image src={switchSvg} alt={'Mon espace club'} />
								<p>Mon Espace Club</p>
							</Link>
							<hr className='w-full' />
						</>
					) : (
						<></>
					);
				}
				return (
					<>
						<div className='min-w-[200px] p-2'>
							<div className='animate-pulse flex items-center justify-start gap-2'>
								<div className='bg-gray-300 rounded-xl w-full h-[60px]'></div>
							</div>
						</div>
						<hr className='w-full' />
					</>
				);

			default:
				return <></>;
		}
	};

	return getComponent();
};

export default ClientController;
